import { useRef, useState } from "react"
import styled from "styled-components"

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  size,
  useFloating,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react"
import { useDismiss, useRole } from "@floating-ui/react"
import { useDebounceCallback } from "usehooks-ts"

import { cardBoxShadow } from "src/constants/shadows"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { mColors } from "src/ui/colors"
import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import { TInputContainerProps } from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type TOmittedInputContainerProps = Omit<
  TInputContainerProps,
  | "shrink"
  | "tabIndex"
  | "endAdornment"
  | "cursor"
  | "children"
  | "showClearButton"
  | "onClear"
>

export type TComboboxOption = {
  label: string
  value: string
  description?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  selectedLabelText?: string
  disabled?: boolean
}

type TComboboxProps = {
  selectedValue: string
  options: TComboboxOption[]
  onChange: (value: string) => void
  onSearch: (input: string) => void
  required?: boolean
  searchDelay?: number
  initialInput?: string
} & TOmittedInputContainerProps

export function Combobox({
  label,
  startAdornment,
  selectedValue,
  options,
  onChange,
  onSearch,
  required,
  searchDelay = 500,
  initialInput,
}: TComboboxProps) {
  const [open, setOpen] = useState(false)
  // This is the current virtually focused/hovered item in the list
  const [activeIndex, setActiveIndex] = useState(0)
  const [input, setInput] = useState(initialInput ?? "")

  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue
  )

  const listRef = useRef<HTMLElement[]>([])

  const debouncedSearch = useDebounceCallback((value: string) => {
    onSearch(value)
  }, searchDelay)

  const floating = useFloating<HTMLDivElement>({
    open,
    // This handles only open changed events triggered by floating-ui
    onOpenChange: (open) => {
      if (open) {
        setActiveIndex(selectedIndex)
      } else {
        if (!selectedValue) {
          setInput("")
        }

        onSearch("")
      }

      setOpen(open)
    },
    middleware: [
      offset(8),
      flip(),
      shift(),
      size({
        apply: ({ rects, elements }) => {
          elements.floating.style.width = `${rects.reference.width}px`
        },
      }),
    ],
    transform: true,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(floating.context)
  const role = useRole(floating.context, {
    role: "combobox",
  })

  const listNav = useListNavigation(floating.context, {
    activeIndex,
    selectedIndex,
    listRef,
    onNavigate: (index) => {
      if (index !== null) {
        setActiveIndex(index)
      }
    },
    virtual: true,
    loop: true,
    scrollItemIntoView: true,
  })

  const interactions = useInteractions([dismiss, role, listNav])

  function handleSelect() {
    const selectedOption = options[activeIndex]

    setInput(selectedOption.selectedLabelText ?? selectedOption.label)
    onChange(selectedOption.value)
    setOpen(false)
  }

  return (
    <div>
      <div
        ref={floating.refs.setReference}
        {...interactions.getReferenceProps()}
      >
        <MTextField
          label={label}
          value={input}
          onChange={(value) => {
            setActiveIndex(0)
            setInput(value)
            debouncedSearch(value)
            onChange("")
          }}
          onKeyDown={(e) => {
            if (e.key === "Tab" || e.shiftKey) {
              return
            }

            if (e.key === "Enter") {
              e.preventDefault()
              handleSelect()
            } else {
              setOpen(true)
            }
          }}
          startAdornment={startAdornment}
          endAdornment={<ChevronDown width={16} color="unset" />}
          required={required}
          onClick={() => {
            setOpen((prev) => !prev)
          }}
          onBlur={(e) => {
            // Due to us having to set `closeOnFocusOut={false}` the automatic close on focusout is disabled and we have to handle it here instead
            if (!floating.refs.floating.current?.contains(e.relatedTarget)) {
              setOpen(false)
            }
          }}
        />
      </div>
      <FloatingPortal>
        {open && options.length > 0 && (
          <FloatingFocusManager
            context={floating.context}
            initialFocus={-1}
            modal={false}
            /* 
              When we let floating-ui handle focusout events, it does not play well with the MUI dialog. The browser gives the dialog the focus when you click an item in the listbox.
              We can hopefully give back control when we have our own dialog component.
            */
            closeOnFocusOut={false}
          >
            <PopoverContent
              ref={floating.refs.setFloating}
              style={floating.floatingStyles}
              {...interactions.getFloatingProps()}
            >
              <div>
                {options.map((option, index) => (
                  <ComboboxItem
                    key={option.value}
                    {...interactions.getItemProps({
                      ref: (node) => {
                        if (listRef.current && node) {
                          listRef.current[index] = node
                        }
                      },
                      onClick: !option.disabled ? handleSelect : undefined,
                      active: index === activeIndex,
                      selected: index === selectedIndex,
                    })}
                    $active={index === activeIndex}
                    $selected={index === selectedIndex}
                    $disabled={option.disabled}
                    aria-disabled={option.disabled}
                  >
                    {option.icon && (
                      <div>
                        <option.icon width={24} />
                      </div>
                    )}
                    <div>
                      {option.label}
                      {option.description && (
                        <MText variant="bodyS" color="secondary">
                          {option.description}
                        </MText>
                      )}
                    </div>
                  </ComboboxItem>
                ))}
              </div>
            </PopoverContent>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  )
}

const ComboboxItem = styled.div<{
  $active: boolean
  $selected: boolean
  $disabled?: boolean
}>`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;

  cursor: ${({ $disabled }) => ($disabled ? "auto" : "pointer")};
  padding: ${spacing.M};
  background-color: ${({ $active, $selected, $disabled }) => {
    if ($selected) {
      return mColors.neutralDark
    }

    if ($active && !$disabled) {
      return mColors.neutral
    }

    return mColors.neutralLight
  }};

  color: ${({ $disabled }) => {
    if ($disabled) {
      return mColors.textInactive
    }

    return mColors.textPrimary
  }};
`

const PopoverContent = styled.div`
  max-height: 200px;
  overflow-y: auto;
  background-color: ${mColors.neutralLight};
  border: 1px solid ${mColors.divider};
  border-radius: 0.5rem;
  /*
    MUI creates a stacking context for it's floating label which makes the popover render behind it, this makes sure the popover stacking context renders above
    1300 is the z-index of the MUI dialog
  */
  z-index: ${Z_INDEX_ABOVE_MODAL};
  ${cardBoxShadow}
`
